import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyAQleAN1CSCfRCxgixWHOlL9PRR_b17hsI",
  authDomain: "wibit-8fb4b.firebaseapp.com",
  projectId: "wibit-8fb4b",
  storageBucket: "wibit-8fb4b.appspot.com",
  messagingSenderId: "1022609375448",
  appId: "1:1022609375448:web:38b98f8e7725ef555097ad",
  measurementId: "G-Q2J93LR2DP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);     // Initialize Auth
export const db = getFirestore(app); 
export const googleProvider = new GoogleAuthProvider();