// HalfCircleGauge.js
import React from 'react';

const HalfCircleGauge = ({ value }) => {
  const radius = 100;
  const circumference =  Math.PI * radius;
  const strokeDashoffset = circumference * (1 - value / 100);

  return (
    <svg width="200" height="100">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#A855F7" />
          <stop offset="100%" stopColor="#8B5CF6" />
        </linearGradient>
      </defs>
      {/* Background Half-Circle */}
      <path
        d="M 10 90 A 80 80 0 0 1 190 100"
        fill="none"
        stroke="#eee"
        strokeWidth="10"
      />
      {/* Progress Half-Circle */}
      <path
        d="M 10 90 A 80 80 0 0 1 190 100"
        fill="none"
        stroke="url(#gradient)"
        strokeWidth="10"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        style={{
          transition: 'stroke-dashoffset 0.5s ease-out',
        }}
      />
      {/* Percentage Text */}
      <text
        x="100"
        y="80"
        textAnchor="middle"
        fill="#A855F7"
        fontSize="24"
        fontWeight="bold"
      >
        {value}%
      </text>
    </svg>
  );
};

export default HalfCircleGauge;