import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithPopup,
} from 'firebase/auth';

const LoginPage = () => {
  const [isSignup, setIsSignup] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Toggle between Login and Sign Up form
  const toggleForm = () => {
    setIsSignup(!isSignup);
    setError(''); // Clear errors when switching forms
  };

  // Handle form submission for email/password login/signup
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignup) {
        // Check if the email is already in use before signing up
        const methods = await fetchSignInMethodsForEmail(auth, email);
        if (methods.length > 0) {
          setError('This email is already in use. Try logging in instead.');
        } else {
          // Sign up the user
          await createUserWithEmailAndPassword(auth, email, password);
          console.log('User signed up successfully');
          navigate('/wifi-control'); // Redirect after successful signup
        }
      } else {
        // Log in the user
        await signInWithEmailAndPassword(auth, email, password);
        console.log('User logged in successfully');
        navigate('/wifi-control'); // Redirect after successful login
      }
    } catch (error) {
      console.error('Error with email login/signup:', error);
      setError(error.message);
    }
  };

  // Handle Google Login
  const handleGoogleLogin = async () => {
    setError('');

    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('User signed in with Google:', result.user);
      navigate('/wifi-control'); // Redirect after successful Google login
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      setError(error.message);
    }
  };

  return (
    <div style={pageStyle}>
      <nav style={navStyle}>
        <div style={logoStyle}>WiBit</div>
      </nav>
      <div style={contentWrapperStyle}>
        <div style={loginContainerStyle}>
          <h1 style={headingStyle}>{isSignup ? 'Sign Up' : 'Log In'}</h1>
          {error && <p style={errorStyle}>{error}</p>}
          <form onSubmit={handleSubmit} style={formStyle}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={inputStyle}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={inputStyle}
            />
            <button type="submit" style={buttonStyle} className="button">
              {isSignup ? 'Sign Up' : 'Log In'}
            </button>
          </form>

          {/* Divider */}
          <div style={dividerStyle}>
            <span style={dividerTextStyle}>or</span>
          </div>

          {/* Google Login Button */}
          <button onClick={handleGoogleLogin} style={googleButtonStyle} className="button">
            <i className="fab fa-google" style={googleIconStyle}></i> Sign in with Google
          </button>

          <p onClick={toggleForm} style={toggleStyle} className="toggle">
            {isSignup
              ? 'Already have an account? Log in'
              : "Don't have an account? Sign up"}
          </p>
        </div>
      </div>
    </div>
  );
};

// Page Styling
const pageStyle = {
  textAlign: 'center',
  minHeight: '100vh',
  color: '#fff',
  fontFamily: "'Roboto', sans-serif",
  backgroundColor: '#0D0D0D',
  position: 'relative',
  overflowX: 'hidden',
};

const navStyle = {
  position: 'fixed',
  top: 0,
  width: '100%',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 50px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 10,
};

const logoStyle = {
  fontSize: '1.8rem',
  color: '#8B5CF6',
  fontWeight: 'bold',
};

const contentWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
};

const loginContainerStyle = {
  backgroundColor: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
  padding: '40px',
  borderRadius: '15px',
  backdropFilter: 'blur(10px)', // Frosted glass effect
  width: '400px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
  zIndex: 1,
};

const headingStyle = {
  fontSize: '2.5rem',
  color: '#fff',
  background: 'linear-gradient(90deg, #A855F7, #8B5CF6)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: '20px',
};

const errorStyle = {
  color: 'red',
  fontSize: '1.1rem',
  marginBottom: '15px',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
};

const inputStyle = {
  padding: '15px',
  fontSize: '1rem',
  borderRadius: '10px',
  border: '1px solid #eee',
  outline: 'none',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#fff',
  transition: 'border 0.3s ease',
};

const buttonStyle = {
  padding: '15px',
  fontSize: '1rem',
  borderRadius: '10px',
  border: 'none',
  background: 'linear-gradient(90deg, #A855F7, #8B5CF6)',
  color: '#fff',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
};

const googleButtonStyle = {
  margin: '20px auto 0 auto', // Center horizontally
  padding: '15px',
  fontSize: '1rem',
  borderRadius: '10px',
  border: 'none',
  backgroundColor: '#DB4437',
  color: '#fff',
  fontWeight: 'bold',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
};

const googleIconStyle = {
  marginRight: '10px',
  fontSize: '1.2rem',
};

const toggleStyle = {
  marginTop: '20px',
  color: '#fff',
  textDecoration: 'underline',
  cursor: 'pointer',
  transition: 'color 0.3s ease',
};

const dividerStyle = {
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0',
};

const dividerTextStyle = {
  flexGrow: 1,
  textAlign: 'center',
  color: '#ccc',
  fontSize: '1rem',
  position: 'relative',
};

const styles = `
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
  @import url('https://use.fontawesome.com/releases/v5.15.3/css/all.css');

  .input:hover {
    border: 1px solid #A855F7;
  }

  .button:hover {
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(168, 85, 247, 0.2);
  }

  .toggle:hover {
    color: #A855F7;
  }
`;

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default LoginPage;