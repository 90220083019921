import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';  // Import ProtectedRoute
import WiFiControlPage from './components/WifiControlPage';
import WalletManagementPage from './components/WalletManagementPage';
import LoginPage from './components/LoginPage';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import SettingsPage from './components/SettingsPage';  // Import SettingsPage
import TokenomicsPage from './components/TokenomicsPage';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />  {/* HomePage route */}
        <Route path="/tokenomics" element={<TokenomicsPage />} />  {/* TokenomicsPage route */}
        <Route path="/login" element={<LoginPage />} />  {/* LoginPage route */}
        <Route 
          path="/wifi-control" 
          element={
            <ProtectedRoute>
              <WiFiControlPage />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/wallet-management" 
          element={
            <ProtectedRoute>
              <WalletManagementPage />
            </ProtectedRoute>
          } 
        />

        {/* Add the new SettingsPage route */}
        <Route 
          path="/settings" 
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;