import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const location = useLocation();  // Get current location
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out");
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isHomePageOrLogin = (!user && location.pathname === '/' || location.pathname === '/login' || location.pathname === '/tokenomics');

  return (
    <nav style={navStyle}>
      <Link to="/" style={logoLinkStyle}>
        <div style={logoStyle}>WiBit</div>
      </Link>


      {/* Only show the menu if not on mobile */}
      {!isMobile && (
        <ul style={{ ...navLinksStyle, ...(isMobileMenuOpen ? mobileMenuOpenStyle : {}) }}>
          {isHomePageOrLogin ? (
            <>
              <li>
                <a onClick={() => scrollToSection('features')} style={navLinkStyle}>
                  Features
                </a>
              </li>
              <li>
                <a onClick={() => scrollToSection('overview')} style={navLinkStyle}>
                  Overview
                </a>
              </li>
              <li>
                <a onClick={() => scrollToSection('roadmap')} style={navLinkStyle}>
                  Roadmap
                </a>
              </li>
              <li>
                <a onClick={() => scrollToSection('team')} style={navLinkStyle}>
                  Team
                </a>
              </li>
              <li>
                <a onClick={() => scrollToSection('contact')} style={navLinkStyle}>
                  Contact
                </a>
              </li>
            </>
          ) : (
            <div style={userLinksStyle}>
              <Link to="/wifi-control" style={navButtonLinkStyle}>
                Wi-Fi Control
              </Link>
              <Link to="/wallet-management" style={navButtonLinkStyle}>
                Wallet Management
              </Link>
              <Link to="/settings" style={navButtonLinkStyle}>
                Settings
              </Link>
            </div>
          )}
        </ul>
      )}

      <div style={navbarButtonsStyle}>
        {user ? (
          <button onClick={handleSignOut} style={buttonStyle}>
            Log Out
          </button>
        ) : (
          isHomePageOrLogin && (
            <Link to="/login">
              <button style={buttonStyle}>Sign In</button>
            </Link>
          )
        )}
      </div>
    </nav>
  );
};

// Styles for the Navbar
const navStyle = {
  position: 'fixed',
  top: 0,
  width: '100%',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 50px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 1000,
  boxSizing: 'border-box',
};

const logoStyle = {
  fontSize: '1.8rem',
  color: '#8B5CF6',
  fontWeight: 'bold',
};

const logoLinkStyle = {
  textDecoration: 'none', // Prevent the default link underline
};

const navLinksStyle = {
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '30px',
  flexDirection: 'row',
  margin: 0,
  cursor: 'pointer',
};

const mobileNavLinksStyle = {
  flexDirection: 'column',
  position: 'absolute',
  top: '70px',
  left: 0,
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  padding: '20px 0',
  textAlign: 'center',
};

const mobileMenuOpenStyle = {
  display: 'block',
};

const navLinkStyle = {
  color: '#fff',
  textDecoration: 'none',
  fontSize: '1rem',
  transition: 'color 0.3s',
};

const navbarButtonsStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
};

const userLinksStyle = {
  display: 'flex',
  gap: '15px',
};

const navButtonLinkStyle = {
  textDecoration: 'none',
  color: '#fff',
  fontSize: '1rem',
  fontWeight: 'bold',
  padding: '10px 20px',
  borderRadius: '10px',
  background: 'linear-gradient(90deg, #34A853, #4285F4)',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  transition: 'background 0.3s ease, transform 0.3s ease',
};

const buttonStyle = {
  padding: '10px 20px',
  borderRadius: '10px',
  border: 'none',
  background: 'linear-gradient(90deg, #A855F7, #8B5CF6)',
  color: '#fff',
  fontWeight: 'bold',
  cursor: 'grab',
  fontSize: '1rem',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  transition: 'background 0.3s ease, transform 0.3s ease',
};

// Hamburger Menu Styles for Mobile
const hamburgerMenuStyle = {
  display: 'none',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '25px',
  height: '20px',
  cursor: 'grab',
  '@media (max-width: 768px)': {
    display: 'flex',
  },
};

const barStyle = {
  width: '100%',
  height: '3px',
  backgroundColor: '#fff',
};

export default Navbar;