import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from '@solana/web3.js'; // Solana imports

const WiFiControlPage = () => {
  const [isThrottlingEnabled, setIsThrottlingEnabled] = useState(false);
  const [throttleLevel, setThrottleLevel] = useState(50); // Slider percentage
  const [throttleMode, setThrottleMode] = useState('Medium'); // Low, Medium, High options
  const [loading, setLoading] = useState(true);
  const [earnings, setEarnings] = useState(0); // Store hypothetical earnings
  const [updateStatus, setUpdateStatus] = useState(''); // Track update status
  const [walletAddress, setWalletAddress] = useState(null); // Track user's wallet address
  const navigate = useNavigate();

  // Hypothetical multiplier for earnings
  const earningsMultiplier = 0.05; // User earns 0.05 tokens per percentage point of throttling

  useEffect(() => {
    const fetchThrottlingSetting = async () => {
      const user = auth.currentUser;

      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsThrottlingEnabled(
            userData.wifiLimits?.throttlingEnabled || false
          );
          setThrottleLevel(userData.wifiLimits?.throttleLevel || 50);
          setThrottleMode(userData.wifiLimits?.throttleMode || 'Medium');
          setWalletAddress(userData.walletAddress || null); // Get user's wallet address
          calculateEarnings(userData.wifiLimits?.throttleLevel || 50);
        }
      }
      setLoading(false);
    };

    fetchThrottlingSetting();
  }, []);

  const saveSettings = async () => {
    setUpdateStatus('Saving...'); // Show saving status

    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(
          userDocRef,
          {
            wifiLimits: {
              throttlingEnabled: isThrottlingEnabled,
              throttleLevel,
              throttleMode,
            },
          },
          { merge: true }
        );
        setUpdateStatus('Settings saved successfully!');
      }
    } catch (error) {
      console.error('Error updating throttling settings:', error);
      setUpdateStatus('Error saving settings.');
    }
  };

  const toggleThrottling = async () => {
    setUpdateStatus(isThrottlingEnabled ? 'Disabling...' : 'Enabling...'); // Display enabling/disabling status

    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);

        // Toggle the throttlingEnabled value and save it to Firestore
        const newThrottlingEnabledState = !isThrottlingEnabled;

        await setDoc(
          userDocRef,
          {
            wifiLimits: {
              throttlingEnabled: newThrottlingEnabledState, // Save the toggled value
              throttleLevel, // Keep the same throttle level and mode
              throttleMode,
            },
          },
          { merge: true }
        );

        // Confirm the state change by updating the UI
        setIsThrottlingEnabled(newThrottlingEnabledState);
        setUpdateStatus(
          newThrottlingEnabledState ? 'Throttling enabled' : 'Throttling disabled'
        );
      }
    } catch (error) {
      console.error('Error toggling throttling:', error);
      setUpdateStatus('Error updating throttling status.');
    }
  };

  // Handle payment to upgrade Wi-Fi (remove throttling)
  const payForBetterWiFi = async () => {
    if (!walletAddress) {
      setUpdateStatus('Connect a wallet to pay for better Wi-Fi.');
      return;
    }

    try {
      const provider = window.solana;
      if (provider && provider.isPhantom) {
        await provider.connect();
        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: provider.publicKey,
            toPubkey: new PublicKey('YOUR_RECEIVING_WALLET_ADDRESS'), // Replace with your receiving wallet
            lamports: LAMPORTS_PER_SOL * 0.01, // Example: 0.01 SOL payment for better Wi-Fi
          })
        );
        const { signature } = await provider.signAndSendTransaction(transaction);
        await provider.connection.confirmTransaction(signature, 'confirmed');

        setIsThrottlingEnabled(false); // Disable throttling after payment
        setUpdateStatus('Payment successful! Throttling disabled for 24 hours.');
      } else {
        setUpdateStatus('Please install a Solana wallet like Phantom.');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setUpdateStatus('Error during payment. Please try again.');
    }
  };

  const handleSliderChange = (e) => {
    const value = e.target.value;
    setThrottleLevel(value);
    updateThrottleMode(value);
    calculateEarnings(value); // Calculate earnings based on throttling level
    setUpdateStatus(''); // Reset update status when changing settings
  };

  const updateThrottleMode = (value) => {
    if (value < 33) setThrottleMode('Low');
    else if (value < 66) setThrottleMode('Medium');
    else setThrottleMode('High');
  };

  // Hypothetical earnings calculation based on throttle level
  const calculateEarnings = (throttlingLevel) => {
    const calculatedEarnings = throttlingLevel * earningsMultiplier;
    setEarnings(calculatedEarnings.toFixed(2)); // Store with 2 decimal precision
  };

  if (loading)
    return <div style={loadingStyle}>Loading...</div>;

  return (
    <div style={pageStyle}>
      <nav style={navStyle}>
        <div style={logoStyle}>WiBit</div>
      </nav>
      <div style={contentWrapperStyle}>
        <h1 style={headingStyle}>Wi-Fi Control</h1>
        <button
          onClick={toggleThrottling}
          style={buttonStyle}
          className="button"
        >
          {isThrottlingEnabled ? 'Disable Throttling' : 'Enable Throttling'}
        </button>

        {isThrottlingEnabled && (
          <div style={controlContainerStyle}>
            <h2 style={subHeadingStyle}>
              Throttling Level: {throttleMode}
            </h2>
            <div style={sliderContainerStyle}>
              <input
                type="range"
                min="0"
                max="100"
                value={throttleLevel}
                onChange={handleSliderChange}
                style={sliderStyle}
              />
              <div style={throttleDisplayStyle}>
                {throttleLevel}% Throttling
              </div>
            </div>

            {/* Display hypothetical earnings */}
            <div style={earningsContainerStyle}>
              <h3 style={earningsHeadingStyle}>Earnings Estimate</h3>
              <p style={earningsTextStyle}>
                Earned Tokens: {earnings} tokens/day
              </p>
              <p style={descriptionStyle}>
                Throttle your Wi-Fi to earn more tokens. The higher the throttling
                percentage, the more tokens you earn.
              </p>
            </div>

            {/* Pay for better Wi-Fi button */}
            <button
              onClick={payForBetterWiFi}
              style={payButtonStyle}
              className="button"
            >
              Pay for Better Wi-Fi
            </button>

            {/* Save Settings Button */}
            <button
              onClick={saveSettings}
              style={saveButtonStyle}
              className="button"
            >
              Save Settings
            </button>

            {/* Wallet Management Button */}
            <button
              onClick={() => navigate('/wallet-management')}
              style={walletButtonStyle}
              className="button"
            >
              Go to Wallet Management
            </button>

            {/* Display update status */}
            {updateStatus && (
              <div style={statusStyle}>{updateStatus}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Styling objects
const pageStyle = {
  textAlign: 'center',
  minHeight: '100vh',
  color: '#fff',
  fontFamily: "'Roboto', sans-serif",
  backgroundColor: '#0D0D0D',
  position: 'relative',
  overflowX: 'hidden',
};

const navStyle = {
  position: 'fixed',
  top: 0,
  width: '100%',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 50px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 10,
};

const logoStyle = {
  fontSize: '1.8rem',
  color: '#8B5CF6',
  fontWeight: 'bold',
};

const contentWrapperStyle = {
  paddingTop: '100px',
  paddingBottom: '50px',
};

const headingStyle = {
  fontSize: '3.5rem',
  marginBottom: '20px',
  background: 'linear-gradient(90deg, #A855F7, #8B5CF6)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '2px',
};

const subHeadingStyle = {
  fontSize: '2rem',
  marginBottom: '10px',
  color: '#FFD700',
};

const controlContainerStyle = {
  marginTop: '30px',
  textAlign: 'center',
};

const sliderContainerStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const sliderStyle = {
  width: '80%',
  maxWidth: '500px',
  marginTop: '20px',
  accentColor: '#A855F7',
};

const throttleDisplayStyle = {
  marginTop: '10px',
  fontSize: '1.5rem',
  color: '#A855F7',
};

const earningsContainerStyle = {
  marginTop: '30px',
  padding: '20px',
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '10px',
  maxWidth: '600px',
  margin: 'auto',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
};

const earningsHeadingStyle = {
  fontSize: '1.8rem',
  color: '#FFD700',
};

const earningsTextStyle = {
  fontSize: '1.5rem',
  color: '#fff',
};

const descriptionStyle = {
  fontSize: '1.2rem',
  color: '#ddd',
  marginTop: '20px',
  padding: '0 30px',
  maxWidth: '600px',
  margin: 'auto',
};

const buttonStyle = {
  padding: '15px 30px',
  fontSize: '1.2rem',
  borderRadius: '10px',
  border: 'none',
  background: 'linear-gradient(90deg, #8B5CF6, #A855F7)',
  color: '#fff',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
  marginTop: '20px',
};

const saveButtonStyle = {
  ...buttonStyle,
  background: 'linear-gradient(90deg, #34D399, #10B981)',
};

const walletButtonStyle = {
  ...buttonStyle,
  background: 'linear-gradient(90deg, #F59E0B, #D97706)',
  marginLeft: '10px',
};

const payButtonStyle = {
  ...buttonStyle,
  background: 'linear-gradient(90deg, #F472B6, #EC4899)',
};

const statusStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#FFD700',
  borderRadius: '10px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
};

const loadingStyle = {
  textAlign: 'center',
  color: '#fff',
  fontSize: '1.5rem',
  marginTop: '50px',
};

// Additional styles
const styles = `
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

  .button:hover {
    transform: translateY(-3px);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

// Inject the styles into the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default WiFiControlPage;