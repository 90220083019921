import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { updateEmail, updatePassword } from 'firebase/auth';
import { FaSave } from 'react-icons/fa'; // Importing save icon
import { FiMail, FiLock, FiBell, FiEye, FiMoon, FiGlobe, FiShield, FiDatabase, FiMessageCircle } from 'react-icons/fi'; // Importing icons

const SettingsPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [privacyMode, setPrivacyMode] = useState('Public');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState('en');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [dataLimit, setDataLimit] = useState(50);
  const [communicationMethod, setCommunicationMethod] = useState('email');
  const [loading, setLoading] = useState(true);
  const [updateStatus, setUpdateStatus] = useState('');

  // Fetch user settings on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      const user = auth.currentUser;

      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setEmail(user.email); // Get email from Firebase auth
          setNotificationsEnabled(userData.settings?.notificationsEnabled || false);
          setPrivacyMode(userData.settings?.privacyMode || 'Public');
          setIsDarkMode(userData.settings?.isDarkMode || false);
          setLanguage(userData.settings?.language || 'en');
          setIs2FAEnabled(userData.settings?.is2FAEnabled || false);
          setDataLimit(userData.settings?.dataLimit || 50);
          setCommunicationMethod(userData.settings?.communicationMethod || 'email');
        }
      }
      setLoading(false);
    };

    fetchSettings();
  }, []);

  // Save updated settings to Firestore
  const saveSettings = async () => {
    setUpdateStatus('Saving...');
    try {
      const user = auth.currentUser;

      if (user) {
        // Update Firestore settings
        const userDocRef = doc(db, "users", user.uid);
        await setDoc(
          userDocRef,
          {
            settings: {
              notificationsEnabled,
              privacyMode,
              isDarkMode,
              language,
              is2FAEnabled,
              dataLimit,
              communicationMethod,
            },
          },
          { merge: true }
        );

        // If email or password has changed, update Firebase auth as well
        if (user.email !== email) {
          await updateEmail(user, email);
        }
        if (password) {
          await updatePassword(user, password);
        }

        setUpdateStatus('Settings saved successfully!');
      }
    } catch (error) {
      console.error("Error saving settings:", error);
      setUpdateStatus('Error saving settings.');
    }

    // Hide the update status message after a few seconds
    setTimeout(() => setUpdateStatus(''), 3000);
  };

  if (loading)
    return (
      <div style={loadingStyle}>
        <div className="loader"></div>
        <p>Loading settings...</p>
      </div>
    );

  return (
    <div style={pageStyle}>
      <div style={cardStyle}>
        <h1 style={headingStyle}>Settings</h1>

        {/* Update Email */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiMail style={iconStyle} />
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={inputStyle}
          />
        </div>

        {/* Update Password */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiLock style={iconStyle} />
            Password
          </label>
          <input
            type="password"
            placeholder="New password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={inputStyle}
          />
        </div>

        {/* Enable/Disable Notifications */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiBell style={iconStyle} />
            Enable Notifications
          </label>
          <label className="switch">
            <input
              type="checkbox"
              checked={notificationsEnabled}
              onChange={(e) => setNotificationsEnabled(e.target.checked)}
            />
            <span className="slider round"></span>
          </label>
        </div>

        {/* Privacy Mode */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiEye style={iconStyle} />
            Privacy Mode
          </label>
          <select
            value={privacyMode}
            onChange={(e) => setPrivacyMode(e.target.value)}
            style={selectStyle}
          >
            <option value="Public">Public</option>
            <option value="Friends-only">Friends-only</option>
            <option value="Private">Private</option>
          </select>
        </div>

        {/* Dark Mode Toggle */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiMoon style={iconStyle} />
            Enable Dark Mode
          </label>
          <label className="switch">
            <input
              type="checkbox"
              checked={isDarkMode}
              onChange={(e) => setIsDarkMode(e.target.checked)}
            />
            <span className="slider round"></span>
          </label>
        </div>

        {/* Language Selection */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiGlobe style={iconStyle} />
            Preferred Language
          </label>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            style={selectStyle}
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
            {/* Add more languages as needed */}
          </select>
        </div>

        {/* Two-Factor Authentication */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiShield style={iconStyle} />
            Enable Two-Factor Authentication (2FA)
          </label>
          <label className="switch">
            <input
              type="checkbox"
              checked={is2FAEnabled}
              onChange={(e) => setIs2FAEnabled(e.target.checked)}
            />
            <span className="slider round"></span>
          </label>
        </div>

        {/* Data Usage Limit */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiDatabase style={iconStyle} />
            Data Usage Limit (GB)
          </label>
          <input
            type="number"
            value={dataLimit}
            onChange={(e) => setDataLimit(e.target.value)}
            style={inputStyle}
            min="1"
            max="100"
          />
        </div>

        {/* Communication Preferences */}
        <div style={settingItemStyle}>
          <label style={labelStyle}>
            <FiMessageCircle style={iconStyle} />
            Preferred Communication
          </label>
          <select
            value={communicationMethod}
            onChange={(e) => setCommunicationMethod(e.target.value)}
            style={selectStyle}
          >
            <option value="email">Email</option>
            <option value="sms">SMS</option>
            <option value="push">Push Notifications</option>
          </select>
        </div>

        {/* Save Button */}
        <button onClick={saveSettings} style={buttonStyle}>
          <FaSave style={{ marginRight: '8px' }} />
          Save Settings
        </button>

        {/* Display update status */}
        {updateStatus && <div style={statusStyle}>{updateStatus}</div>}
      </div>
    </div>
  );
};

// Style objects for the page and components
const pageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #1f1c2c, #928dab)',
  color: '#fff',
  minHeight: '100vh',
  padding: '20px',
};

const cardStyle = {
  background: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '15px',
  padding: '40px',
  maxWidth: '600px',
  width: '100%',
  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
};

const headingStyle = {
  fontSize: '2.5rem',
  marginBottom: '30px',
  textAlign: 'center',
};

const settingItemStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '25px',
};

const labelStyle = {
  flex: '1',
  fontSize: '1.2rem',
  display: 'flex',
  alignItems: 'center',
};

const iconStyle = {
  marginRight: '10px',
  fontSize: '1.5rem',
  color: '#A855F7',
};

const inputStyle = {
  flex: '2',
  padding: '10px',
  fontSize: '1rem',
  borderRadius: '5px',
  border: 'none',
  outline: 'none',
  marginLeft: '10px',
};

const selectStyle = {
  ...inputStyle,
  appearance: 'none',
  background: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY0IiBoZWlnaHQ9IjI0OCIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9InN2Z0ljb24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTAgMGgxNjR2MjQ4SDBWMHoiIGZpbGw9Im5vbmUiLz4KICA8cGF0aCBkPSJNMTI4LjY1NiAxMDkuMzQzYTMgMyAwIDAgMC00LjI5MiAwTDUyLjY0IDMxLjYzOWExIDAgMCAxIDEuNDYxLTEuNDYxbjIyLjI5MiAyMi4yOTJhMSAxIDAgMCAxIDAgMS40NjJsLTcwIDcwYTkgOSAwIDAgMCAwIDEzLjI5Mmw3MCA3MGE0IDQgMCAwIDAgNS42NzggMGw3MC03MGE5IDkgMCAwIDAgMCAxMy4yOTJMMTEwLjY0NiAxNzAuMzZhMSAxIDAgMCAxIDAgLTEuNDYxTDEyOC42NTYgMTI5LjY2NGExIDEgMCAwIDAgMC0xLjQ2MXoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+) no-repeat right center',
  backgroundSize: '1rem',
  color: '#fff',
};

const buttonStyle = {
  width: '100%',
  padding: '15px',
  fontSize: '1.2rem',
  background: 'linear-gradient(90deg, #34A853, #4285F4)',
  borderRadius: '10px',
  color: '#fff',
  cursor: 'pointer',
  border: 'none',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '30px',
  transition: 'background 0.3s ease',
};

const statusStyle = {
  marginTop: '20px',
  fontSize: '1.1rem',
  textAlign: 'center',
};

const loadingStyle = {
  ...pageStyle,
  flexDirection: 'column',
};

export default SettingsPage;