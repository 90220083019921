import React from 'react';
import { Link } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';

// Import any additional components or styles if necessary

const TokenomicsPage = () => {
  return (
    <div style={pageStyle}>
      <div style={contentWrapperStyle}>
        <h1 style={headingStyle}>Tokenomics</h1>
        <p style={descriptionStyle}>
          Discover how WiBit's tokenomics incentivize users to optimize network bandwidth and contribute to a decentralized Wi-Fi ecosystem.
        </p>

        {/* Token Distribution Section */}
        <div style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>Token Distribution</h2>
          <p style={sectionTextStyle}>
            WiBit utilizes a token-based incentive mechanism to reward users who voluntarily reduce their bandwidth usage during peak congestion times. The token distribution is designed to promote network efficiency and user participation.
          </p>

          <div style={tokenDistributionContainerStyle}>
            <div style={tokenCardStyle}>
              <h3 style={tokenCardHeadingStyle}>User Rewards</h3>
              <p style={tokenCardTextStyle}>
                <strong>50%</strong> of tokens are allocated to users who contribute to bandwidth optimization by reducing their usage during peak times.
              </p>
            </div>
            <div style={tokenCardStyle}>
              <h3 style={tokenCardHeadingStyle}>Development Fund</h3>
              <p style={tokenCardTextStyle}>
                <strong>25%</strong> is reserved for ongoing development, maintenance, and improvements to the WiBit platform.
              </p>
            </div>
            <div style={tokenCardStyle}>
              <h3 style={tokenCardHeadingStyle}>Partnerships</h3>
              <p style={tokenCardTextStyle}>
                <strong>15%</strong> is allocated for partnerships with venues, ISPs, and other stakeholders to expand the network.
              </p>
            </div>
            <div style={tokenCardStyle}>
              <h3 style={tokenCardHeadingStyle}>Reserve Fund</h3>
              <p style={tokenCardTextStyle}>
                <strong>10%</strong> is held in reserve for future initiatives and unforeseen expenses.
              </p>
            </div>
          </div>
        </div>

        {/* How It Works Section */}
        <div style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>How It Works</h2>
          <p style={sectionTextStyle}>
            WiBit's tokenomics are built to create a self-sustaining ecosystem that rewards positive user behavior and ensures network efficiency.
          </p>

          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Monitoring Bandwidth Usage:</strong> The system monitors user bandwidth consumption in real-time.
            </li>
            <li style={listItemStyle}>
              <strong>Voluntary Bandwidth Reduction:</strong> Users can opt-in to reduce their bandwidth usage during peak times through the WiBit platform.
            </li>
            <li style={listItemStyle}>
              <strong>Token Rewards:</strong> Users are rewarded with WiBit tokens proportional to their contribution to alleviating network congestion.
            </li>
            <li style={listItemStyle}>
              <strong>Smart Contracts:</strong> All transactions and reward distributions are handled by secure and transparent smart contracts on the blockchain.
            </li>
          </ul>
        </div>

        {/* Token Utility Section */}
        <div style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>Token Utility</h2>
          <p style={sectionTextStyle}>
            WiBit tokens are not just rewards; they serve multiple functions within the ecosystem.
          </p>

          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Access Premium Features:</strong> Use tokens to unlock premium services like higher bandwidth caps during non-peak hours.
            </li>
            <li style={listItemStyle}>
              <strong>Voting Rights:</strong> Participate in governance decisions by voting on platform updates and new features.
            </li>
            <li style={listItemStyle}>
              <strong>Marketplace Transactions:</strong> Exchange tokens for goods and services within the WiBit marketplace.
            </li>
            <li style={listItemStyle}>
              <strong>Staking Rewards:</strong> Stake tokens to earn additional rewards and support network stability.
            </li>
          </ul>
        </div>

        {/* Smart Contract Overview Section */}
        <div style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>Smart Contract Overview</h2>
          <p style={sectionTextStyle}>
            Our smart contracts ensure secure and transparent transactions within the WiBit network.
          </p>

          <pre style={codeBlockStyle}>
{`pragma solidity ^0.8.0;

contract BandwidthRewards {
    mapping(address => uint256) public contributions;
    mapping(address => uint256) public rewards;

    function recordContribution(address user, uint256 amount) public {
        contributions[user] += amount;
    }

    function issueReward(address user) public {
        uint256 rewardAmount = contributions[user] / 100; // Example logic
        rewards[user] += rewardAmount;
    }
}`}
          </pre>
          <p style={sectionTextStyle}>
            The smart contract handles the recording of user contributions and the issuance of rewards, maintaining transparency and security in the token distribution process.
          </p>
        </div>

        {/* Call to Action Section */}
        <div style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>Join the Network</h2>
          <p style={sectionTextStyle}>
            Be a part of the WiBit ecosystem and start earning rewards today. Together, we can create a more efficient and decentralized network for everyone.
          </p>
          <Link to="/signup">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button style={getStartedButtonStyle}>Get Started</button>
            </div>
          </Link>
        </div>
      </div>

      {/* Footer Section */}
      <footer style={footerStyle}>
        <div style={footerContentStyle}>
          <p>&copy; {new Date().getFullYear()} WiBit. All rights reserved.</p>
          <div style={socialIconsStyle}>
            {/* Add social media icons */}
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={socialIconStyle}
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://github.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={socialIconStyle}
            >
              <i className="fab fa-github"></i>
            </a>
            {/* Add more icons */}
          </div>
        </div>
      </footer>
    </div>
  );
};

// Styling (reuse the styles from your theme)
const pageStyle = {
  textAlign: 'center',
  minHeight: '100vh',
  color: '#fff',
  fontFamily: "'Roboto', sans-serif",
  backgroundColor: '#0D0D0D',
  position: 'relative',
  overflowX: 'hidden',
};

const contentWrapperStyle = {
  paddingTop: '100px',
};

const headingStyle = {
  fontSize: '3rem',
  marginBottom: '20px',
  background: 'linear-gradient(90deg, #A855F7, #8B5CF6)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '2px',
};

const descriptionStyle = {
  fontSize: '1.4rem',
  color: '#ccc',
  marginBottom: '40px',
  padding: '0 20px',
  maxWidth: '800px',
  margin: '0 auto',
};

const sectionStyle = {
  margin: '60px auto',
  padding: '30px',
  textAlign: 'left',
  maxWidth: '800px',
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '15px',
  backdropFilter: 'blur(10px)',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
};

const sectionHeadingStyle = {
  fontSize: '2rem',
  color: '#fff',
  marginBottom: '15px',
};

const sectionTextStyle = {
  fontSize: '1.2rem',
  color: '#ddd',
  lineHeight: '1.6',
};

const tokenDistributionContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: '30px',
};

const tokenCardStyle = {
  flex: '1 1 calc(45% - 20px)',
  background: 'rgba(0, 0, 0, 0.7)',
  padding: '20px',
  margin: '10px',
  borderRadius: '10px',
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
};

const tokenCardHeadingStyle = {
  fontSize: '1.5rem',
  color: '#A855F7',
  marginBottom: '10px',
};

const tokenCardTextStyle = {
  fontSize: '1.1rem',
  color: '#ccc',
};

const listStyle = {
  listStyleType: 'none',
  paddingLeft: '0',
  marginTop: '20px',
};

const listItemStyle = {
  marginBottom: '15px',
  fontSize: '1.2rem',
  color: '#ddd',
};

const codeBlockStyle = {
  backgroundColor: '#1A1A1A',
  padding: '20px',
  borderRadius: '10px',
  marginTop: '20px',
  color: '#00FF00',
  fontFamily: "'Courier New', Courier, monospace",
  fontSize: '0.9rem',
  overflowX: 'auto',
};

const getStartedButtonStyle = {
  padding: '15px 30px',
  justifyContent: 'center',
  marginTop: '20px',
  cursor: 'pointer',
  borderRadius: '10px',
  border: 'none',
  backgroundColor: '#A855F7',
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  transition: 'background-color 0.3s',
};

const footerStyle = {
  backgroundColor: '#1A1A1A',
  padding: '20px',
  marginTop: '60px',
};

const footerContentStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '800px',
  margin: '0 auto',
  flexWrap: 'wrap',
};

const socialIconsStyle = {
  display: 'flex',
  gap: '20px',
};

const socialIconStyle = {
  color: '#fff',
  fontSize: '1.5rem',
  transition: 'color 0.3s',
};

// Additional styles for hover effects
const styles = `
.card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.4);
}

.button:hover {
  background-color: #8B5CF6;
}

.nav a:hover {
  color: #A855F7;
}

.social-icon:hover {
  color: #A855F7;
}
`;

// Inject the styles into the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default TokenomicsPage;